
import { defineComponent, ref } from "vue";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import LogOutModal from "@/app/private/pages/profile/components/LogOutModal.vue";

export default defineComponent({
  name: "ProfileNavigationMenu",
  components: { LogOutModal, BaseIcon, BaseButton },
  emits: ["close-profile-nav"],
  setup() {
    let logOutModal = ref<InstanceType<typeof LogOutModal>>();
    let options = ref<{ routeName: string; text: string; iconSrc: string }[]>([
      {
        routeName: "Profile",
        text: "My Profile",
        iconSrc: "static/icons/profile/profile.svg",
      },
      {
        routeName: "Billing",
        text: "My Billing",
        iconSrc: "static/icons/profile/billing.svg",
      },
      {
        routeName: "Ratings",
        text: "My Ratings",
        iconSrc: "static/icons/profile/rating.svg",
      },
      {
        routeName: "Documents",
        text: "My Documents",
        iconSrc: "static/icons/shared/documents.svg",
      },
      {
        routeName: "Security",
        text: "Security",
        iconSrc: "static/icons/profile/lock.svg",
      },
    ]);

    const openLogOutModal = () => {
      logOutModal.value?.open();
    };
    return {
      options,
      logOutModal,
      openLogOutModal,
    };
  },
});
