
import { defineComponent, ref } from "vue";
import BaseModal from "@/app/core/components/BaseModal.vue";
import { useRouter } from "vue-router";
import BaseButton from "@/app/core/components/buttons/BaseButton.vue";
import BaseIcon from "@/app/core/components/BaseIcon.vue";
import { useRoot } from "@/store/Store";
import { useAuthModule } from "@/store/modules/AuthModule";

export default defineComponent({
  name: "LogOutModal",
  components: { BaseIcon, BaseButton, BaseModal },
  setup() {
    const authCtx = useAuthModule();
    let opened = ref<boolean>(false);
    const router = useRouter();
    const open = () => {
      opened.value = true;
    };
    const submit = () => {
      authCtx.actions.logout().then(() => {
        opened.value = false;
        router.push({ name: "Login" });
      });
    };
    return {
      open,
      opened,
      submit,
    };
  },
});
